import Experience from "./../Experience.js";

export default class PanControls {
  constructor() {
    this.experience = new Experience();
    this.camera = this.experience.camera;

    // Pan controls variables
    this.panDistance = 0;
    this.inertiaDeltaX = 0;
    this.inertiaDecayFactor = 0.97;
    this.initialInertiaMultiplier = 18;
    this.panFactor = 5500;
  }

  panStartHandler() {
    this.updateRotationAfterAnimation(this.camera.cameraContainer.rotation.y);

    if (this.inertiaAnimationId !== null) {
      cancelAnimationFrame(this.inertiaAnimationId);
      this.inertiaAnimationId = null;
    }

    this.inertiaDeltaX = 0;
  }

  panHandler(e) {
    // 2.43 - - 2.07
    const scroll = this.panDistance + e.deltaX;
    this.rotateCamera(scroll / this.panFactor);
    this.camera.instance.updateMatrixWorld();

    this.inertiaDeltaX = e.velocityX * this.initialInertiaMultiplier;
  }

  panEndHandler(e) {
    this.panDistance += e.deltaX;
    this.inertiaDeltaX = e.velocityX * this.initialInertiaMultiplier;
    this.handleInertia();
  }

  handleInertia() {
    if (Math.abs(this.inertiaDeltaX) > 0.1) {
      this.inertiaDeltaX *= this.inertiaDecayFactor;
      this.panDistance += this.inertiaDeltaX;
      this.rotateCamera(this.panDistance / this.panFactor);

      if (Math.abs(this.inertiaDeltaX) > 0.1) {
        this.inertiaAnimationId = requestAnimationFrame(() =>
          this.handleInertia(),
        );
      }
    } else {
      this.inertiaAnimationId = null;
    }
  }

  clearInertia() {
    this.inertiaDeltaX = 0;
  }

  rotateCamera(amount) {
    let maxedAmount = amount;

    if (amount > 2.5) {
      maxedAmount = 2.5
    } else if (amount < 2.0) {
      maxedAmount = 2.0
    }
    this.camera.cameraContainer.rotation.y = maxedAmount * Math.PI - Math.PI;
  }

  updateRotationAfterAnimation(rotation) {
    this.panDistance = ((rotation + Math.PI) / Math.PI) * this.panFactor;
  }
}
