<script>
  import {onMount} from 'svelte';
  import Logo from '../components/Logo.svelte';
  import Players from '../components/Players.svelte';
  import Hint from '../components/Hint.svelte';
  import Sound from '../components/Sound.svelte';
  import ActivePlayer from '../components/ActivePlayer.svelte';
  import {state} from '../../store/state.js';
  import {activePlayer} from '../../store/activePlayer.js';
  import Pointer from '../components/Pointer.svelte';
  import {LOADING_STATES, LOOK_AROUND_STATES, STATE} from '../../Experience/config.js';
  import Onboarding from '../components/Onboarding.svelte';
  import FocusOverlay from '../components/FocusOverlay.svelte';
  import Overlay from '../components/Overlay.svelte';
  import LangSelector from '../components/LangSelector.svelte';
  import {selectedLanguage} from '../../store/selectedLanguage.js';
  import {authorizeToken, getUserInfo, refreshAccessToken} from '../services/unidyService.js';
  import {submissionCompleted} from '../../store/submissionCompleted.js';
  import HoverRotate from '../components/HoverRotate.svelte';
  import ShortlistConfirm from '../components/ShortlistConfirm.svelte';
  import Share from '../components/Share.svelte';
  import {getStorateItem, STORAGE_KEYS} from '../services/storageService.js';
  import {completeSubmission} from '../services/completeService.js';
  import YoutubePopup from '../components/YoutubePopup.svelte';

  state.subscribe(async (currentState) => {
    if (currentState !== STATE.SHORTLIST_SAVE) {
      return;
    }

    if (getStorateItem(STORAGE_KEYS.HALL_OF_FAME) && getStorateItem(STORAGE_KEYS.UNIDY_TOKEN)) {
      await completeSubmission();
      return;
    }

    state.set(STATE.FIRST_PERSON_ACTIVE);
  });

  onMount(async () => {
    await authorizeToken();
    await refreshAccessToken();
    await getUserInfo();
  });
</script>

	<div class="elements">
		<Overlay />
		<Onboarding />
		<Pointer />

		{#key $selectedLanguage}
			{#if !LOADING_STATES.includes($state)}
				<Logo />
			{/if}

			{#if LOOK_AROUND_STATES.includes($state) || $state === STATE.SHORTLIST_ACTIVE || $state === STATE.SHORTLIST_CONFIRM}
				<!--{#if !$submissionCompleted}-->
				<!--	<Players />-->
				<!--{/if}-->
				<LangSelector type="small" />
			{/if}

			<!--{#if $state === STATE.SHORTLIST_CONFIRM}-->
<!--				<ShortlistConfirm />-->
<!--			{/if}-->

			<!--{#if $state === STATE.SHARE}-->
<!--				<Share />-->
<!--			{/if}-->
			{#if $state === STATE.YOUTUBE_POPUP_ACTIVE}
				<YoutubePopup />
			{/if}

			{#if $state === STATE.FIRST_PERSON_ACTIVE || $state === STATE.FIRST_PERSON_NOT_ACTIVE || $state === STATE.FIRST_PERSON_ACTIVE_AND_HOVERING || $state === STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO}
				<Sound />
				<Hint />
				<HoverRotate />
			{/if}

			{#if $state === STATE.PLAYER_DETAIL || $state === STATE.PLAYER_DETAIL_PREV_NEXT_ANIMATION_START}
				<ActivePlayer />
			{/if}

			{#if $state === STATE.PLAYER_DETAIL_ENTER_ANIMATION_START || $state === STATE.PLAYER_DETAIL || $state === STATE.PLAYER_DETAIL_PREV_NEXT_ANIMATION_START}
				<FocusOverlay />
			{/if}
		{/key}

		<div class="dev">
			<span>state: {$state}</span>
			<span>activePlayer: {$activePlayer}</span>
			<span>submissionCompleted: {$submissionCompleted}</span>
		</div>
	</div>

<style>
  .elements {
    position: absolute;
    top: 24px;
    left: 24px;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
  }

  @media screen and (min-width: 640px) {
    .elements {
      top: 48px;
      left: 64px;
      height: calc(100% - 96px);
      width: calc(100% - 128px);
    }
  }

  .dev {
    position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3;
	display: none;
	flex-direction: column;
	background-color: white;
	padding: 16px 32px;
	font-size:14px;
  }
</style>
