import Experience from "./../Experience.js";

export default class ScrollControls {
  constructor() {
    this.experience = new Experience();
    this.camera = this.experience.camera;
    this.scene = this.experience.scene;

    // Scroll controls variables
    this.scrollDistance = 0;
    this.scrollFactor = 20000;
  }

  scrollHandler(e) {
    this.scrollDistance += e.wheelDeltaY / this.scrollFactor;
    // 4.5 - 3.9 - 3.3
    // 2.43 - 2.25 - 2.04
    // 2.5 - - 2.0
    if (this.scrollDistance > 2.5) {
      this.scrollDistance = 2.5
    } else if (this.scrollDistance < 2) {
      this.scrollDistance = 2
    }

    this.camera.cameraContainer.rotation.y = this.scrollDistance * Math.PI - Math.PI;
    this.camera.instance.updateMatrixWorld();
  }

  updateRotationScroll() {
    this.scrollDistance =
      (this.camera.cameraContainer.rotation.y + Math.PI) / Math.PI;
  }
}
