<script>
  import {fade} from 'svelte/transition';
  import {translate} from '../../translations/index.js';
  import Svg from './Svg.svelte';
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';

  let soundOn = false;

  const toggleSound = (muted) => {
    const element = document.querySelector('#background-video');

    if (!element) {
      return;
    }

    if (muted) {
      soundOn = !muted;
      element.muted = muted;
	} else {
      soundOn = !soundOn;
      element.muted = !soundOn;
	}
  }

  const turnSoundOff = (state) => {
    if (state !== STATE.YOUTUBE_POPUP_ACTIVE) {
	  return;
	}

    toggleSound(true);
  }

  $: turnSoundOff($state);
</script>

<button class="sound" on:click={() => toggleSound()} transition:fade={{ duration: 300 }}>
	<Svg
			icon={soundOn ? 'icon-audio-on' : 'icon-audio-off'}
			width={32}
			height={32}
			--fill="var(--color-white)"/>

	<span>{soundOn ? translate('hof.sound.on') : translate('hof.sound.off')}</span>
</button>

<style>
  .sound {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    pointer-events: all;
    font-weight: 500;
    font-size: 12px;
  }

  @media screen and (min-width: 640px) {
    .sound {
      font-size: 14px;
    }
  }
</style>
