import { writable } from "svelte/store";

export const players = writable([
  {
    id: 0,
    active: false,
    shortlist: false,
    firstname: "Aline",
    lastname: "Zeler",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/ZELER.jpg",
    video: "",
    youtube: "LQTy-0iihbc"
  },
  {
    id: 1,
    active: false,
    shortlist: false,
    firstname: "Eric",
    lastname: "Gerets",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/GERETS.jpg",
    video: "",
    youtube: "uf3Ky0AXK5k"
  },
  {
    id: 2,
    active: false,
    shortlist: false,
    firstname: "Franky",
    lastname: "Van der Elst",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/VANDERELST.jpg",
    video: "",
    youtube: "Mp9xgs4Gsvg"
  },
  {
    id: 3,
    active: false,
    shortlist: false,
    firstname: "Jan",
    lastname: "Ceulemans",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/CEULEMANS.jpg",
    video: "",
    youtube: "EmCZf2_0SeQ"
  },
  {
    id: 4,
    active: false,
    shortlist: false,
    firstname: "Jean-Marie",
    lastname: "Pfaff",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/PFAFF.jpg",
    video: "",
    youtube: "qW-l0QIsa-g"
  },
  {
    id: 5,
    active: false,
    shortlist: false,
    firstname: "Luc",
    lastname: "Nilis",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/NILIS.jpg",
    video: "",
    youtube: "dEoNDY36SJY"
  },
  {
    id: 6,
    active: false,
    shortlist: false,
    firstname: "Michel",
    lastname: "Preud'homme",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/PREUDHOMME.jpg",
    video: "",
    youtube: "QC5w3ijoqHA"
  },
  {
    id: 7,
    active: false,
    shortlist: false,
    firstname: "Pär",
    lastname: "Zetterberg",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/ZETTERBERG.jpg",
    video: "",
    youtube: "Hkd6SYSrktI"
  },
  {
    id: 8,
    active: false,
    shortlist: false,
    firstname: "Thomas",
    lastname: "Buffel",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/BUFFEL.jpg",
    video: "",
    youtube: "Z1HjlY8g9N8"
  },
  {
    id: 9,
    active: false,
    shortlist: false,
    firstname: "Vincent",
    lastname: "Kompany",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/KOMPANY.jpg",
    video: "",
    youtube: "FP3AxP9433A"
  },
  {
    id: 10,
    active: false,
    shortlist: false,
    firstname: "Wesley",
    lastname: "Sonck",
    club: "",
    games: "",
    logoUrl: "",
    imageUrl: "portraits/SONCK.jpg",
    video: "",
    youtube: "3PMtcLM48ZA"
  }
]);
