import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { get } from "svelte/store";
import EventEmitter from "./EventEmitter.js";
import Experience from "../Experience.js";
import { STATE } from "../config.js";
import { state } from "../../store/state.js";
import { players } from "../../store/players.js";

export default class Resources extends EventEmitter {
  constructor(sources) {
    super();

    this.experience = new Experience();
    this.loader = this.experience.loader;
    this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck;
    this.sources = sources;

    this.items = {};
    this.toLoad = this.sources.length;
    this.loaded = 0;
    this.loadingComplete = false;

    this.setLoadingManager();
    this.setLoaders();
    this.startLoading();
  }

  setLoadingManager() {
    this.loadingManager = new THREE.LoadingManager(
      () => {
        // Send state to store
        state.set(STATE.LOADING_COMPLETE);
        this.loadingComplete = true;
      },
      (itemUrl, itemsLoaded, itemsTotal) => {
        this.loader.onLoadingProgress(itemsLoaded, itemsTotal);
      },
    );
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader(this.loadingManager);
    this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager);
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(
      this.loadingManager,
    );
  }

  startLoading() {
    // Send state to store
    state.set(STATE.LOADING);

    // Load each source
    for (const source of this.sources) {
      if (source.type === "font") {
        const font = new FontFace(source.name, `url(${source.path})`, {
          style: "normal",
          unicodeRange: "U+000-5FF",
          weight: source.weight,
        });

        font.load().then((loadedFont) => {
          document.fonts.add(loadedFont);
          this.sourceLoaded(source, loadedFont);
        });
      } else if (source.type === "gltfModel") {
        this.loaders.gltfLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(source.path, (file) => {
          if (source.id !== "backdropPaintingFrame" && source.id !== "backdropPaintingFrameDark") {
            file.encoding = THREE.sRGBEncoding;
            file.colorSpace = THREE.SRGBColorSpace;
          }

          this.sourceLoaded(source, file);
        });
      } else if (source.type === "cubeTexture") {
        this.loaders.cubeTextureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.id] = file;

    this.loaded++;

    if (this.loaded === this.toLoad) {
      this.trigger("ready");
    }
  }
}
