export const hallOfFame = {
  "hof.start.title": "Pro League Hall of Fame",
  "hof.start.btn": "Entrez dans le Hall of Fame",
  "hof.start.text": "Découvrez ici les 11 légendes qui ont le plus marqué les 50 ans de la Pro League.",
  "hof.start.note": "*Cette liste a été établie sur la base des votes des fans en septembre et octobre 2024.",
  "hof.start.message":
    "Sur cette page, nous utilisons les capteurs de votre téléphone, et vous ne nous avez pas accordé l'accès aux capteurs. Si vous souhaitez changer votre choix, fermez cette page et rouvrez-la. Cliquez sur 'Autoriser' lorsque la demande apparaît.",

  "hof.players.activeplayer.remove": "Retirer de vos légendes",
  "hof.players.activeplayer.add": "Ajoutez-le à vos légendes",
  "hof.players.activeplayer.close": "Fermer",

  "hof.players.favorites": "légendes",
  "hof.players.all": "Shortlist",
  "hof.players.players": "Shortlist",
  "hof.players.empty":
    "Vous n'avez encore aucun joueur sur votre liste de présélection",
  "hof.players.submit": "Soumettez vos légendes",
  "hof.players.max.1": "Vous avez déjà",
  "hof.players.max.2": "légendes sélectionnés!",

  "hof.player.matches": "mts.",

  "hof.shortlist-confirm.title": "Confirmez votre sélection",
  "hof.shortlist-confirm.text":
    "Sûr de votre choix? Confirmez alors les trois légendes via votre compte Pro League.",
  "hof.shortlist-confirm.btn": "Continuer",
  "hof.shortlist-confirm.btn.back": "Retourner",

  "hof.share.title": "Votre choix a été enregistré",
  "hof.share.text":
    "Merci pour votre vote au Hall of Fame ! Nous introniserons dix légendes de la Pro League à la fin du mois d'octobre. Partagez dès maintenant votre sélection avec vos amis et votre famille.",
  "hof.share.btn.close": "Fermer",
  "hof.share.visual.title": "Partagez votre choix:",
  "hof.share.visual.download.timeline": "Télécharger (timeline) ",
  "hof.share.visual.download.story": "Télécharger (story) ",
  "hof.share.visual.share.timeline": "Partager (timeline)",
  "hof.share.visual.share.story": "Partager (story)",
  "hof.share.visual.share.text": "Ma sélection pour le Pro League Hall of Fame",

  "hof.hint.scroll": "Faites défiler pour tourner",
  "hof.hint.swipe": "Balayez pour tourner",

  "hof.sound.on": "Son activé",
  "hof.sound.off": "Son désactivé",
};
