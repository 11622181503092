<script>

  import {fade} from 'svelte/transition';
  import Svg from './Svg.svelte';
  import {STATE} from '../../Experience/config.js';
  import {state} from '../../store/state.js';
  import {activePlayer} from '../../store/activePlayer.js';
  import {players} from '../../store/players.js';

  const hidePopup = () => {
	state.set(STATE.FIRST_PERSON_ACTIVE);
  }
</script>

<div class="popup" transition:fade={{ duration: 400 }}>
	<div class="popup__inner" transition:fade={{ duration: 400, delay: 200 }}>
		<button class="btn btn--square btn--border buttons-close" on:click={() => hidePopup()}>
			<Svg
				icon=icon-close
				width={24}
				height={24}
			/>
		</button>

		<iframe
			src={`https://www.youtube-nocookie.com/embed/${$players[$activePlayer].youtube}`}
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			referrerpolicy="strict-origin-when-cross-origin"
			allowfullscreen
		></iframe>
	</div>
</div>

<style>
	.popup {
      position: fixed;
	  top: 0;
	  left: 0;
	  z-index: 1234;
	  display: flex;
	  align-items: center;
      width: 100%;
	  height: 100%;
      pointer-events: all;
	  background-color: var(--color-black-80);
	  padding: 80px 4vw 4vw 4vw;
	  box-sizing: border-box;
    }

	.popup__inner {
      position: relative;
	  width: 100%;
      max-height: 100%;
      aspect-ratio: 16 / 9;
	}

	.btn {
      position: absolute;
      bottom: calc(100% + 16px);
      right: 0;
	}

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
</style>
