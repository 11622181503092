export const hallOfFame = {
  "hof.start.title":
    "Pro League Hall of Fame",
  "hof.start.btn": "Betreed de Hall of Fame",
  "hof.start.text":
    "Ontdek hier welke 11 voetballegendes de grootste impact hebben gehad op 50 jaar Pro League.",
  "hof.start.note": "*deze lijst is samengesteld op basis van een fan voting in september en oktober 2024.",
  "hof.start.message":
    "Op deze pagina maken we gebruik van sensoren in uw telefoon, en u hebt ons geen toegang tot de sensoren verleend. Als u uw keuze wilt wijzigen, sluit dan deze pagina en heropen deze. Klik op 'Sta toe' wanneer het verzoek verschijnt.",

  "hof.players.activeplayer.remove": "Verwijder uit jouw legends",
  "hof.players.activeplayer.add": "Voeg toe aan jouw legends",
  "hof.players.activeplayer.close": "Sluiten",

  "hof.players.favorites": "legends",
  "hof.players.all": "Shortlist",
  "hof.players.players": "Shortlist",
  "hof.players.empty": "Je hebt nog geen legends gekozen",
  "hof.players.submit": "Stuur jouw legends in",
  "hof.players.max.1": "Je hebt al",
  "hof.players.max.2": "legends geselecteerd!",

  "hof.player.matches": "wed.",

  "hof.shortlist-confirm.title": "Bevestig jouw selectie",
  "hof.shortlist-confirm.text":
    "Ben je zeker van jouw keuze? Bevestig dan de drie legendes via jouw Pro League-account.",
  "hof.shortlist-confirm.btn.confirm": "Verder",
  "hof.shortlist-confirm.btn.back": "Ga terug",

  "hof.share.title": "Jouw keuze is geregistreerd",
  "hof.share.text":
    "Bedankt voor jouw stem! Eind oktober huldigen we tien Pro League Legends in. Deel jouw selectie nu met vrienden en familie.",
  "hof.share.btn.close": "Sluiten",
  "hof.share.visual.title": "Deel jouw keuze:",
  "hof.share.visual.download.timeline": "Download (tijdlijn) ",
  "hof.share.visual.download.story": "Download (story) ",
  "hof.share.visual.share.timeline": "Deel (tijdlijn)",
  "hof.share.visual.share.story": "Deel (story)",
  "hof.share.visual.share.text":
    "Mijn selectie voor de Pro League Hall of Fame",

  "hof.hint.scroll": "Scroll om te draaien",
  "hof.hint.swipe": "Swipe om te draaien",

  "hof.sound.on": "Geluid aan",
  "hof.sound.off": "Geluid uit",
};
